<template>
  <page-skeleton theme="light" :steps="false">
    Resources
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";

export default {
  components: {
    PageSkeleton
  },
  metaInfo: {
    title: 'Resources',
    meta: [
      { vmid: 'description', name: 'description', content: '' }
    ]
  }
}
</script>
